import i18n from "i18next";
import { initReactI18next, I18nextProvider } from "react-i18next";
import en from "./en.json";
import th from "./th.json";
import ch from "./ch.json";

const getLang = () => {
  try {
    return localStorage.getItem("lang") || "th";
  } catch (error) {}
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: { ...en },
      },
      th: {
        translation: { ...th },
      },
      ch: {
        translation: { ...ch },
      },
    },
    lng: getLang(),
    fallbackLng: "en",

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

export default i18n;
