import { createContext, useReducer } from "react";

export const UserContext = createContext(null);

export const initialState = {
  credit: 0,
  customer_address: "",
  customer_email: "",
  customer_first_name: "",
  customer_id: null,
  customer_img: "",
  customer_last_name: "",
  customer_name: "",
  customer_tel: "",
  point: 0,
};

const userReducer = (state = initialState, action) => {
  const { payload } = action;
  if (action.type === "SET_USER") {
    return {
      ...state,
      ...payload,
    };
  }

  return state;
};

export const UserProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, initialState);

  return (
    <UserContext.Provider value={{ userState, dispatch }}>
      {children}
    </UserContext.Provider>
  );
};
